import classNames from 'classnames'

import AsteriAd from '@/components/AsteriAd/AsteriAd'
import Leaderboard from '@/components/Layout/Leaderboard/Leaderboard'
import { useTheme } from '@/components/ThemeProvider/ThemeProvider'
import Twitch from '@/components/Twitch/Twitch'
import { useLoadAds } from '@/contexts/ads'
import useDirectories from '@/utils/useDirectories'

import styles from './Main.module.scss'

const LEADERBOARD_BLACKLIST = ['combo-vote']

const SIDEBAR_BLACKLIST = [
  'about-us',
  'account',
  'apply',
  'bug-report',
  'confirm-password',
  'contact-us',
  'forgot-password',
  'newsletter',
  'privacy',
  'reset-password',
  'signin',
  'signup',
]

type Props = {
  artCrop?: string
  banner?: React.ReactNode
  children: React.ReactNode
}

const GRADIENT_DARK = 'linear-gradient(to bottom, rgba(18, 19, 21, 0.5), rgba(18, 19, 21, 1))'
const GRADIENT_LIGHT = 'linear-gradient(to bottom, rgba(249, 250, 251, 0.5), rgba(249, 250, 251, 1))'

// https://www.epochconverter.com
const asteriEnabled = Date.now() > 1744322400000 && Date.now() < 1744333200000
const asteriRegion = undefined

const twitchEnabled = false

const Main = ({ artCrop, banner, children }: Props) => {
  const { dir } = useDirectories()
  const loadAds = useLoadAds()
  const [theme] = useTheme()

  const gradient = theme === 'dark' ? GRADIENT_DARK : GRADIENT_LIGHT

  const showLeaderboard = loadAds !== false && !LEADERBOARD_BLACKLIST.includes(dir)
  const showSidebar = loadAds !== false && !SIDEBAR_BLACKLIST.includes(dir)

  return (
    <>
      {artCrop && (
        <div className={styles.backgroundContainer}>
          <div
            className={styles.background}
            style={{
              backgroundImage: `${gradient}, url(${artCrop})`,
            }}
          />
        </div>
      )}
      {banner && <div className={styles.backgroundContainer}>{banner}</div>}
      <main className={styles.main}>
        {showLeaderboard && <Leaderboard />}
        <div className={classNames('d-flex flex-grow-1 p-3', { 'pe-lg-0': showSidebar })}>
          <div className='d-flex w-100'>
            <div className={showSidebar ? styles.left : 'w-100'}>{children}</div>
            {showSidebar && <div className={classNames(styles.sidebar, 'mvSidebar1')} />}
            {asteriEnabled && <AsteriAd region={asteriRegion} />}
            {twitchEnabled && <Twitch />}
          </div>
        </div>
      </main>
    </>
  )
}

export default Main
